<template>
  <Box v-if="content" customClass="is-shadowless is-radiusless">
    <div class="block">
      <div class="block">
        <p class="has-text-weight-semibold tag is-info">#{{ content.iid }}</p>
      </div>

      <h3 class="has-text-weight-bold has-text-dark title is-3 my-3">
        {{ content.title }}
      </h3>

      <div class="mt-2 is-size-7">
        Created at: {{ $filters.convertDateTime(content.created_at) }}
      </div>

    </div>

    <div class="block tags">
      <span
        class="tag has-text-weight-bold mr-2"
        :class="[ content.state === 'opened' ? 'is-light' : 'is-success' ]"
      >
        {{ content.state ? content.state : 'N/A' }}
      </span>

      <span v-for="(label, index) in content.labels"
            :key="index"
            class="tag has-text-weight-bold has-text-white"
            :style="{ 'background-color': label.color }"
      >
            {{ label.name }}
      </span>
    </div>

    <div class="block">
        <div v-if="content.assignees.length > 0" class="is-flex is-align-items-center">
          <figure class="image is-32x32 mr-2">
            <img class="is-rounded" :src="content.assignees[0].avatar_url">
          </figure>
          <div class="has-text-weight-medium">{{ content.assignees[0].name }}</div>
        </div>
    </div>

    <hr/>

    <div v-if="content.description" class="block">
      <h4 class="title is-6">Description</h4>
      <div class="content is-small">
        <VueShowdown :markdown="descHtml" />
      </div>
    </div>

    <!-- <div class="block">sda</div>
    <div class="block">dasd</div> -->

  </Box>
</template>

<script>
import { defineAsyncComponent, toRefs,ref, computed, watch, onMounted } from "vue";

export default {
  name: 'IssueDetailPanel',
   props:{
    item: {
      type: Object,
      required: true,
    }
  },
  components: {
    Box: defineAsyncComponent(() => import('@/components/ds/Box')),
  },
  setup (props) {

    const { item } = toRefs(props)
    const content = ref(null)
    const descHtml = ref('')

    const getContent = () => {
      content.value = Object.assign({}, props.item)
      descHtml.value = ''
      let { description } = content.value

      if (description) {
        descHtml.value = description.toString()
      }
    }

    onMounted(() => {
      getContent()
    })

    watch(item, getContent)

    return {
     descHtml,
     content
    }
  }
}
</script>

<style lang="scss" scoped>

</style>